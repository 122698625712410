<template>
  <v-container fluid>
    <main-layout />
    <v-row class="mx-4" justify-md="center">
      <v-col cols="12" md="4">
        <h2 class="ml-2 mt-8 font-weight-black">
          {{ $t("reset_password.email_send") }}
        </h2>
        <h4 class="ml-3 mb-8 mt-2 font-weight-light">
          {{ $t("reset_password.send_text") }}
        </h4>
      </v-col>
    </v-row>
    <v-row class="mx-4 my-md-2" justify="end" justify-md="center">
      <v-col cols="6" md="2" class="pa-0">
        <v-btn
          color="secondary"
          block
          large
          rounded
          class="text-capitalize text-h6 font-weight-light"
          to="login"
        >
          {{ $t("login.login") }}
        </v-btn>
      </v-col>
    </v-row>
    <v-footer :absolute="true" light color="transparent" class="primary--text">
      <v-row class="mb-10">
        <v-col class="text-center">
          <span class="text-subtitle-1"
            >{{ $t("reset_password.not_reveived_mail") }}
            <a class="font-weight-bold">{{
              $t("reset_password.resend")
            }}</a></span
          >
        </v-col>
      </v-row>
    </v-footer>
  </v-container>
</template>
<script>
import MainLayout from "@/components/layouts/Main.vue";
export default {
  components: {
    MainLayout,
  },
  data: () => ({}),
};
</script>